import React from 'react';
import VideoCamera from '../../assets/images/videocam-24px-01.png'
class CameraSvg extends React.Component {
    render () {
        return (
            <img src={VideoCamera} alt="" loading="lazy" />
        );
    }
}

export default CameraSvg;