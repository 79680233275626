const createElement = (type, args = null) => {
    const tag = document.createElement(type);
    if (args) {
        for (const key in args) {
            if (args.hasOwnProperty(key)) {
                const element = args[key];
                tag.setAttribute(key, element);
            }
        }
    }
    return tag;
} 


const getElementById = id => {
    const element = document.getElementById(id);
    return element;
}

module.exports = {
    createElement,
    getElementById,
}