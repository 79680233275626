import client from "../client";

const roomRepository = {
  async loadRooms(status = "in-progress") {
    const load = await client("v2/rooms", "GET", {
      status,
    });

    return load?.data.length ? load.data : [];
  },
  async endRoomForce(roomSid) {
    const load = await client("v2/rooms/force", "POST", {
      roomSid: roomSid,
    });

    return load;
  },
  async getRoom(roomSid) {
    const load = await client(`v2/rooms/${roomSid}`, "GET", {
      roomSid: roomSid,
    });

    return load?.data ? load.data : null;
  },
  async roomToken(roomSid, roomUniqueName) {
    //TODO: api v1 used here.
    const load = await client(`v1/room/token`, "POST", {
      uid: roomUniqueName,
      sid: roomSid,
    });

    return load?.data ? load.data : null;
  },
  async prepareRoom(roomSid, participantUid, userType) {
    const load = await client(`v2/rooms/prepare`, "POST", {
      roomSid,
      participantUid,
      userType
    });

    return load?.data ? load.data : null;
  }
};

export default roomRepository;
