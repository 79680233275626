import React from 'react';
import MicNone from '../../assets/images/mic_none-24px-01.png'

class MuteSvg extends React.Component{
    render () {
        return (
            <img src={MicNone} alt="" loading="lazy" />
        );
    }
}

export default MuteSvg;