import React from 'react';
import NavBar from './partials/NavBar';
import EndCall from './EndCall';
import Loading from './partials/Loading';
import { modulesRoom } from '../utils/http';
import { withRouter } from "react-router-dom";
import Error from './Error';

class Disconected extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            banner: true,
            modules: null,
            room: null,
            isError: false,
            error: null,
        }
    }
    componentDidMount() {
        const roomId = this.props.match.params.room || null;
        this.getModules(roomId)
        .then(modules => {
            if(parseInt(modules.accountTypeId) === parseInt(process.env.REACT_APP_RTC_M1)){
                this.setState({
                    room: roomId,
                    banner: false,
                    modules: modules,
                    loading: false });
            } else {
                this.setState({
                    room: roomId,
                    modules: modules,
                    loading: false
                });
            }
        })
        .catch(e => {
            this.setState({
                loading: false,
                isError: true,
                error: e.toString()
            });
        })
    }

    getModules = async (roomId) => {
        const modules = await modulesRoom(roomId);
        return modules;
    }

    render () {
        if (!this.state.loading) {
            if(!this.state.isError) { 
                return (
                    <>
                        {(this.state.banner) ? <NavBar></NavBar> : ''}
                        <EndCall modules={this.state.modules} 
                            room={this.state.room} ></EndCall>
                    </>
                );
            } else {
                return (
                    <>
                        <Error error={this.state.error}></Error>
                    </>
                );
            }
            
        } else {
            return(
                <>
                <div className="container">
                        <div className="row">
                            <div className="col col-sm-12 col-md-12 col-lg-12">
                                <div className="pt-4 mt-5">
                                    <div className="endroom-action" >
                                        <br></br>
                                        <Loading></Loading>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default withRouter(Disconected);