import React from 'react';
import Rating from 'react-rating';
import star from '../assets/images/estrella-02.png';
import starGrey from '../assets/images/estrella-01.png';
import {ratingRoom} from '../utils/http';

class EndCall extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ranked: false,
            ratingValue: null,
        }
        this.onRating = this.onRating.bind(this);
    }
    
    downloadLink = () => {
        if(this.props && this.props.modules) {
            const accountTypeId = this.props.modules.accountTypeId.toString();
            const envAccountType = process.env.REACT_APP_RTC_M1;
            if (accountTypeId !== envAccountType)
                return (<a href="https://play.google.com/store/apps/details?id=com.agriconecta.agcta_smallholder_farmer&hl=es_419">Descarga Agriconecta desde aquí.</a>);
        } 

        return;
    }
    
    onRating = () => {
        if (this.state.ratingValue) {
            const { room } = this.props;
            ratingRoom(room, this.state.ratingValue , null)
                .then(response => {
                    this.setState({
                        ranked: true,
                    });
                });
        }
    }

    putRating = (value) => {
        this.setState({
            ratingValue: value,
        });
    }

    rating = () => {
        return (
                <Rating
                    onChange={ this.putRating }
                    emptySymbol={<img src={starGrey} className="icon" alt="" />} 
                    fullSymbol={<img src={star} className="icon" alt="" />}
                    initialRating={this.state.ratingValue}
                />
        );
    }

    registerRating = () => {
        return (
            <>
                <h3 className="display-6">Videollamada finalizada.</h3>
                <p className="lead">
                    Déjanos tu calificación:
                </p>
                { this.rating() }
                <br></br>
                <br></br>
                <p className="endroom-action">
                    <button className="btn btn--rtc" onClick={this.onRating}>Enviar calificación</button>
                </p>
                <div>
                    {this.downloadLink()}
                </div>
            </>
        );
    }
    
    registeredRating = () => {
        return (
            <h3 className="display-6">Gracias por tu calificación.</h3>
        );
    }

    render () {
        return (
            <div className="container">
                <div className="row">
                    <div className="col col-sm-12 col-md-12 col-lg-12">
                        <div className="pt-4 mt-5">
                            <div className="jumbotron endroom-action" >
                                { (!this.state.ranked) ? this.registerRating() : this.registeredRating() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EndCall;