import React from 'react';
import VideoCameraOff from '../../assets/images/videocam_off-24px-01.png'

class NoCameraSvg extends React.Component{
    render () {
        return (
            <img src={VideoCameraOff} alt="" loading="lazy" />
        );
    }
}

export default NoCameraSvg;