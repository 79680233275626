import React from 'react';
import FlipCamera from '../../assets/images/flip_camera_ios-24px-01.png'

class RotateSvg extends React.Component{
    render () {
        return (
            <img src={FlipCamera} alt="" loading="lazy" />
        );
    }
}

export default RotateSvg;