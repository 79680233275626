import React from 'react';
import ScreenShare from '../../assets/images/screen_share-24px-01.png'

class ShareSvg extends React.Component{
    render () {
        return (
            <img src={ScreenShare} alt="" loading="lazy" />
        );
    }
}

export default ShareSvg;