const twilio = require('twilio-video');
const { createElement, getElementById } = require('./dom');
const { CARD_CLASS, CONTAINER_VIDEO, DEFAULT_CAMERA_MODE } = require('./globals');
export const connect = async (token, constraints) => {
    console.log('Twilio options: ', constraints);
    console.log('Twilio version: ' , twilio.version);
    console.log('Twilio constraint', constraints);
    const room = await twilio.connect(token, constraints);
    
    window.CAMERA_MODE = constraints.video.facingMode || DEFAULT_CAMERA_MODE;
    window.room = room;

    // window.room.localParticipant.setNetworkQualityConfiguration({
    //     local: 2,
    //     remote: 1
    // });

    window.room.on('participantReconnecting', remoteParticipant => {
        console.log(`${remoteParticipant.identity} is reconnecting the signaling connection to the Room!`);
        /* Update the RemoteParticipant UI here */
    });

    window.room.on('reconnecting', error => {
        if (error.code === 53001) {
            console.log('Reconnecting your signaling connection!', error.message);
        } else if (error.code === 53405) {
            console.log('Reconnecting your media connection!', error.message);
        }
        /* Update the application UI here */
    });

    window.room.on('reconnected', () => {
        console.log('Reconnected your signaling and media connections!');
        /* Update the application UI here */
    });

    // window.room.on('disconnected', (room, error) => {
    //     if (error.code === 20104) {
    //       console.log('Signaling reconnection failed due to expired AccessToken!');
    //     } else if (error.code === 53000) {
    //       console.log('Signaling reconnection attempts exhausted!');
    //     } else if (error.code === 53204) {
    //       console.log('Signaling reconnection took too long!');
    //     }
    // });

    window.room.on('participantReconnecting', remoteParticipant => {
        console.log(`${remoteParticipant.identity} is reconnecting the signaling connection to the Room!`);
        /* Update the RemoteParticipant UI here */
    });

    window.room.on('participantReconnected', remoteParticipant => {
        console.log(`${remoteParticipant.identity} has reconnected the signaling connection to the Room!`);
        /* Update the RemoteParticipant UI here */
    });

    window.room.on('error', error => {
        console.log('error', error);
    })

    window.room.localParticipant.on('trackEnabled', (track) => {
        if(track.kind === 'video')
            videoCoverOff(track.id);
    });

    window.room.localParticipant.on('trackDisabled', (track) => {
        if(track.kind === 'video')
            videoCoverOn(track.id);
    });

    window.room.localParticipant.on('trackRemoved', (track) => {
        console.log('trackRemoved');
    });

    window.room.localParticipant.on('trackStarted', (track) => {
        console.log('trackStarted');
    });
    
    window.room.localParticipant.on('trackStopped', (track) => {
        console.log('trackStopped');
    });
    return room;
}

const attachContainerVideo = async (track, container, trackId=null) => {
    const containerScreenVideo = container;
    if (containerScreenVideo) {
        while(containerScreenVideo.firstChild)
            containerScreenVideo.removeChild(containerScreenVideo.firstChild);
        
        
        if(trackId){
            const id = (trackId.id || trackId.sid);
            if(trackId.isEnabled === true) {
                console.log('track ',trackId);
                
                containerScreenVideo.setAttribute('track-id', id);
                containerScreenVideo.setAttribute('style', 'z-index: 400');
    
                const containerCover = document.getElementById('video-full-cover');
                containerCover.setAttribute('style', 'z-index: 300; background-color:transparent');
            } else {
                console.log('track else',trackId);
                const id = (trackId.id || trackId.sid);
                containerScreenVideo.setAttribute('track-id', id);
                containerScreenVideo.setAttribute('style', 'z-index: 300');
    
                const containerCover = document.getElementById('video-full-cover');
                containerCover.setAttribute('style', 'z-index: 400; background-color:transparent');
            }
            
        }
        
        containerScreenVideo.append(track);
    }
}

const changeAttachContainer = async (id) => {
    const localTrack = await getLocalTrackId(id);
    const container = getElementById(CONTAINER_VIDEO);
    if (localTrack) {
        attachContainerVideo(localTrack.attach(), container, localTrack);
    } else {
        const remote = await getRemoteTrackId(id);
        if (remote){
            attachContainerVideo(remote.attach(), container, remote);
        }
    }
}

const getLocalTrackId = async (id) => {
    let track = null;
    const local = window.room.localParticipant;
    local.videoTracks.forEach(publication => {
        if (publication.track.id === id) {
            track = publication.track;
        }
    }) 
    return track;
}

const getLocalTrack = async () => {
    let track = null;
    const local = window.room.localParticipant;
    local.videoTracks.forEach(publication => {
        track = publication.track;
    }) 
    return track;
}

const getRemoteTrackId = async id => {
    let trackRemote = null;
    const participants = window.room.participants;
    participants.forEach(publication => {
        publication.videoTracks.forEach(track => {
            if (track.track.sid === id) {
                trackRemote = track.track;
            }
        })
    });
    return trackRemote;
}

const createCardVideo = async (track) => {
    const colDivChild = createElement('div');
    const itemColChild = createElement('div');
    const itemColVideoSrc = createElement('div');
    const itemColCoverVideo = createElement('div');
    const coverImg = createElement('img');

    if(track) {
        if (track.kind === 'video') {
            colDivChild.setAttribute('class', CARD_CLASS);
            itemColChild.setAttribute('class', 'item-card-video');

            itemColVideoSrc.setAttribute('class', 'video-src');
            itemColCoverVideo.setAttribute('class', 'video-cover');
            coverImg.setAttribute('src', '/videocam_off-24px-agriconecta.png');            
        }

        const itemId = (track.id || track.sid);
    
        itemColChild.setAttribute('id', itemId);
        itemColVideoSrc.setAttribute('id', `src-${itemId}`);
        itemColCoverVideo.setAttribute('id', `cover-${itemId}`);
        
        if (track.kind === 'video') {
            itemColCoverVideo.appendChild(coverImg);
            itemColChild.append(itemColCoverVideo);
        }

        itemColVideoSrc.appendChild(track.attach());
        itemColChild.append(itemColVideoSrc);


        colDivChild.setAttribute('id', `parent-${itemId}`);

        colDivChild.append(itemColChild);
    
        colDivChild.addEventListener('click', async () => {
            await changeAttachContainer((track.id || track.sid));
        });
    }
    
    return colDivChild;
}

export const attachLocalTrack = async (tracks, htmlContainer) => {
    await tracks.forEach(async track => {
        const childvideo = await createCardVideo(track);
        htmlContainer.appendChild(childvideo);
        if (track.kind === 'video') {
            const container = getElementById(CONTAINER_VIDEO);
            await attachContainerVideo(track.attach(), container, track);
        }
    });
    return tracks;
}

const attachRemoteTrack = async (tracks, htmlContainer) => {
    let trackClone = null;
    if(tracks) {
        const childvideo = await createCardVideo(tracks);
        htmlContainer.appendChild(childvideo);
        
        if (tracks.kind === 'video') {
            const container = getElementById(CONTAINER_VIDEO);
            await attachContainerVideo(tracks.attach(), container, tracks);
        }
        trackClone = tracks;
    } else {
        console.log('No hay tracks');
    }
    
    return trackClone;
}

export const futureParticipants = () => {
    //Waiting future paricipants
    try {
        console.log('waiting future participants');
        loadingParticipantBanner();
        window.room.on('participantConnected', remoteParticipant => {
            console.log(`An participant ${remoteParticipant.sid} is connected`);
            removeLoadingParticipantBanner();
            //here
            remoteParticipant.tracks.forEach(publication => {
                if (publication.isSubscribed) {
                  const track = publication.track;
                    const localContainer = getElementById('list-card-video');
                    attachRemoteTrack(track, localContainer);
                }
              });
            //HERE
            
            remoteParticipant.on('disconnected', (track) => {
                // console.log('trackUnpublished event', track);
            });
            remoteParticipant.on('networkQualityLevelChanged', (networkQualityLevel, networkQualityStats) => {
                // console.log('trackUnpublished event', networkQualityLevel, networkQualityStats);
            });
            remoteParticipant.on('reconnected', (track) => {
                console.log('reconnected event', track);
            });
            remoteParticipant.on('reconnecting', (track) => {
                console.log('reconnecting event', track);
            });
            remoteParticipant.on('trackDimensionsChanged', (track) => {
                // console.log('trackDimensionsChanged event', track);
            });
            remoteParticipant.on('trackEnabled', (track) => {
                //Fire 
                console.log('trackEnabled event', track);
            });
            remoteParticipant.on('trackDisabled', (track) => {
                //Fire
                console.log('trackDisabled event', track);
            });
            remoteParticipant.on('trackMessage', (track) => {
                // console.log('trackMessage event', track);
            });
            remoteParticipant.on('trackPublished', (track) => {
                // console.log('trackPublished event', track);
            });
            remoteParticipant.on('trackPublishPriorityChanged', (a,b) => {
                // console.log('trackPublished event', a,b);
            });
            remoteParticipant.on('trackStarted', (a, b) => {
                // console.log('trackStarted event', a,b);
            });
            remoteParticipant.on('trackSubscribed', (track, publication) => {
                track.on('message', (data, track) => {
                    // console.log('trackSubscribed.message',data, track);
                })
                track.on('switchedOff', (track) => {
                    // console.log('trackSubscribed.switchedOff', track);
                })
                track.on('switchedOff', (track) => {
                    console.log('trackSubscribed.switchedOff', track);
                })
                track.on('dimensionsChanged', (track) => {
                    // console.log('trackSubscribed.dimensionsChanged', track);

                });
                track.on('disabled', (track) => {
                    if(track.kind === 'video')
                        videoCoverOn(track.sid);
                });
                
                track.on('enabled', (track) => {
                    if(track.kind === 'video')
                        videoCoverOff(track.sid);
                });

                const localContainer = getElementById('list-card-video');
                attachRemoteTrack(track, localContainer);
            });
            remoteParticipant.on('trackSubscriptionFailed', (error, track) => {
                // console.log('trackSubscriptionFailed event', error);
            });

            remoteParticipant.on('trackSwitchedOff', (track) => {
                // console.log('trackSwitchedOff event', track);
            });

            remoteParticipant.on('trackSwitchedOn', (track) => {
                // console.log('trackSwitchedOn event', track);
            });

            remoteParticipant.on('trackUnpublished', (track) => {
                // console.log('trackUnpublished event', track);
            });

            remoteParticipant.on('trackUnsubscribed', async remoteTrack => {
                // console.log('trackSubscribed-x', remoteTrack);
                remoteTrack.detach().forEach(element => element.remove());
                const itemId = (remoteTrack.id || remoteTrack.sid);
                // console.log('track-unsubscribed', itemId);

                const parentElement = document.getElementById(`parent-${itemId}`);
                if (parentElement) {
                    parentElement.remove();
                }

                const attachlocal = await getLocalTrack();
                // console.log('attachlocal', attachlocal);
                //if participan close or exit, reload local video in principal container. 
                if(attachlocal) {
                    const container = getElementById(CONTAINER_VIDEO);
                    // console.log('attachlocal.attach()', attachlocal.attach());
                    attachContainerVideo(attachlocal.attach(), container, attachlocal);
                }
            });
    
        });
        //FIN
        window.room.on('participantDisconnected', async remoteParticipant => {
            console.log('participante disconnected', remoteParticipant.identity, window.room.sid);
            loadingParticipantBanner();
            remoteParticipant.tracks.forEach(async track => {
                const itemId = (track.trackSid || track.id || track.sid );
                console.log('track-unsubscribed-x', itemId);

                const parentElement = document.getElementById(`parent-${itemId}`);
                if (parentElement) {
                    parentElement.remove();
                }
            });

            const attachlocal = await getLocalTrack();
            console.log('attachlocal', attachlocal);
            //if participan close or exit, reload local video in principal container. 
            if(attachlocal) {
                const container = getElementById(CONTAINER_VIDEO);
                console.log('attachlocal.attach()', attachlocal.attach());
                attachContainerVideo(attachlocal.attach(), container, attachlocal);
            }
        });
    } catch (e) {
        console.log(e);
    }
}

export const currentParticipants = async () => {
    //Current participants in room
    try {
        if (window.room.participants) {
            loadingParticipantBanner();
            const participants = window.room.participants;
            const countParticipants = participants.length || participants.size;
            console.log('current participants:', countParticipants);

            participants.forEach(participant => {
                removeLoadingParticipantBanner();
                participant.tracks.forEach(publication => {
                    if (publication.track) {
                        const track = publication.track;
                        const localContainer = document.getElementById('list-card-video');
                        attachRemoteTrack(track, localContainer);
                    }
                });
                // Print the initial Network Quality Level and statistics
                // printNetworkQualityStats(participant.networkQualityLevel, participant.networkQualityStats);

                // Print changes to Network Quality Level and statistics
                // participant.on('networkQualityLevelChanged', printNetworkQualityStats);
                
                participant.on('trackSubscribed', (track, publication) => {
                    console.log('Has suscribed to track');
                    const localContainer = document.getElementById('list-card-video');
                    attachRemoteTrack(track, localContainer);

                    track.on('disabled', (track) => {
                        if(track.kind === 'video')
                            videoCoverOn(track.trackSid);
                    });
                    
                    track.on('enabled', (track) => {
                        if(track.kind === 'video')
                            videoCoverOff(track.trackSid);
                    });
                });

                participant.on('trackUnsubscribed', async remoteTrack => {
                    const itemId = (remoteTrack.trackSid || remoteTrack.id || remoteTrack.sid );
    
                    const parentElement = document.getElementById(`parent-${itemId}`);
                    if (parentElement) {
                        parentElement.remove();
                    }
    
                    const attachlocal = await getLocalTrack();
                    if(attachlocal) {
                        const container = getElementById(CONTAINER_VIDEO);
                        attachContainerVideo(attachlocal.attach(), container, attachlocal);
                    }
                });

                participant.on('trackEnabled', (track) => {
                    if(track.kind === 'video')
                        videoCoverOff(track.trackSid);
                });

                participant.on('trackDisabled', (track) => {
                    if(track.kind === 'video')
                        videoCoverOn(track.trackSid);
                });
            });
            return true;
        } else {
            console.log('Any in room');
        }
   
    } catch (e) {
        console.log(e);
    }
}

export const switchLocalTrackDevice = async (tracks) => {
    try {
        let cameraMode = window.CAMERA_MODE;
        if (cameraMode === DEFAULT_CAMERA_MODE) {
            cameraMode = 'environment';
            window.CAMERA_MODE = 'environment';
        } else {
            cameraMode = DEFAULT_CAMERA_MODE;
            window.CAMERA_MODE = DEFAULT_CAMERA_MODE;
        }

        if (window.room) {
            const local = window.room.localParticipant;
            const localTracks = Array.from(local.tracks.values()).map( (trackPublication) => { return trackPublication.track; });
            const cameraTrack = localTracks.find(track => track.kind === 'video');
            cameraTrack.stop();
            cameraTrack.restart({ facingMode: cameraMode });
        } else {
            tracks.forEach(track => track.stop());
            return tracks;
        }
    } catch (e) {
        throw e;
    }
    
}

export const muteVideoLocalParticipant = async () => {
    let trackStatus;
    const localTracks = window.room.localParticipant.videoTracks;
    localTracks.forEach(publication => {
        if (publication.track.isEnabled){
            publication.track.disable();
        } else {
            publication.track.enable();
        }
        trackStatus = publication.track.isEnabled;
    });
    return trackStatus;
} 

export const muteAudioLocalParticipant = async () => {
    let trackStatus;
    const localTracks = window.room.localParticipant.audioTracks;
    localTracks.forEach(publication => {
        if (publication.track.isEnabled){
            publication.track.disable();
        } else {
            publication.track.enable();
        }
        trackStatus = publication.track.isEnabled;
    });
    return trackStatus;
} 

export const localScreenShare = async () => {
    try {
        return navigator.mediaDevices.getDisplayMedia()
            .then(stream => {
                const streamTrack = stream.getTracks()[0];
                const streamTrackId = streamTrack.id;
                const remoteScreenTrack = new twilio.LocalVideoTrack(streamTrack);
                //Save in local storage screen share object
                localStorage.setItem('screenShareTrackId', streamTrackId);

                window.room.localParticipant.publishTrack(remoteScreenTrack);
                
                //Load in local trackbox
                const localContainer = getElementById('list-card-video');
                attachRemoteTrack(remoteScreenTrack, localContainer);

                stream.onended = async () => {
                    console.log('stream is onended', remoteScreenTrack);
                    remoteScreenTrack.stop();
                    window.room.localParticipant.unpublishTrack(remoteScreenTrack);
                    localStorage.setItem('screenShareTrackId', null);
                    await removeLocalScreenShare(streamTrackId);
                };
                stream.oninactive = async () => {
                    console.log('stream is oninactive', remoteScreenTrack);
                    remoteScreenTrack.stop();
                    localStorage.setItem('screenShareTrackId', null);
                    window.room.localParticipant.unpublishTrack(remoteScreenTrack);
                    await removeLocalScreenShare(streamTrackId);
                };

                return remoteScreenTrack.isEnabled;
            });
    } catch (e) {
        throw new Error(e);
    }
}

export const publishLocalTrack = async (track) => {
    let publishTrack = null;
    try {
        const localTrack = new twilio.LocalVideoTrack(track);
        window.room.localParticipant.publishTrack(localTrack);
        
        //Load in local trackbox
        const localContainer = getElementById('list-card-video');
        attachRemoteTrack(localTrack, localContainer);
        
        publishTrack = localTrack;
    } catch(e) {
        console.log(e);
    }

    return publishTrack;
}

export const unpublishLocalTrack = async (track) => {
    window.room.localParticipant.unpublishTrack(track);
}

export const unloadRoom = () => {
    // Listen to the "beforeunload" event on window to leave the Room
    // when the tab/browser is being closed.
    window.addEventListener('beforeunload', async () => {
        if (window.room) {
            // await stopRoom(window.room.sid, '123');
            window.room.disconnect();
            window.room = null;
        }
    });
    // iOS Safari does not emit the "beforeunload" event on window.
    window.addEventListener('pagehide', async () => {
        if (window.room) {
            // await stopRoom(window.room.sid, '123')
            window.room.disconnect();
            window.room = null;
        }
    });
}

const removeLocalScreenShare = async (trackId) => {
    const parentElement = document.getElementById(`parent-${trackId}`);
    if (parentElement) {
        parentElement.remove();
        const attachlocal = await getLocalTrack();
        //if participan close or exit, reload local video in principal container. 
        if(attachlocal) {
            const container = getElementById(CONTAINER_VIDEO);
            attachContainerVideo(attachlocal.attach(), container, attachlocal);
        }
    } else {
        const attachlocal = await getLocalTrack();
        //if participan close or exit, reload local video in principal container. 
        if(attachlocal) {
            const container = getElementById(CONTAINER_VIDEO);
            attachContainerVideo(attachlocal.attach(), container, attachlocal);
        }
    }
}


export const deleteLocalScreenShare = async (trackId) => {
    const parentElement = document.getElementById(`parent-${trackId}`);
    if (parentElement) {
        parentElement.remove();
        const attachlocal = await getLocalTrack();
        //if participan close or exit, reload local video in principal container. 
        if(attachlocal) {
            const container = getElementById(CONTAINER_VIDEO);
            attachContainerVideo(attachlocal.attach(), container, attachlocal);
        }
    } else {
        const attachlocal = await getLocalTrack();
        //if participan close or exit, reload local video in principal container. 
        if(attachlocal) {
            const container = getElementById(CONTAINER_VIDEO);
            attachContainerVideo(attachlocal.attach(), container, attachlocal);
        }
    }
}

export const disconnect = (token, constraints) => {
    window.room.on('disconnected', async (room, error) => {
        // if(window.room) {
        //     window.room.disconnect();
        //     window.room = null;
        // }
        window.location.href=`/end/${room.sid}`;
    });
}

// const printNetworkQualityStats = (networkQualityLevel, networkQualityStats) => {
//     // Print in console the networkQualityLevel using bars
//     console.log({
//       1: '▃',
//       2: '▃▄',
//       3: '▃▄▅',
//       4: '▃▄▅▆',
//       5: '▃▄▅▆▇'
//     }[networkQualityLevel] || '');
  
//     if (networkQualityStats) {
//       // Print in console the networkQualityStats, which is non-null only if Network Quality
//       // verbosity is 2 (moderate) or greater
//       console.log('Network Quality statistics:', networkQualityStats);
//     }
//   }

const videoCoverOn = (id) => {
    const videoSrc = document.getElementById(`src-${id}`);
    const videoCover = document.getElementById(`cover-${id}`);

    videoCover.setAttribute('style', 'z-index: 200; background-color:black');
    videoSrc.setAttribute('style', 'z-index: 100');
    fullCoverOn(id);
}

const videoCoverOff = (id) => {
    const videoSrc = document.getElementById(`src-${id}`);
    const videoCover = document.getElementById(`cover-${id}`);

    videoSrc.setAttribute('style', 'z-index: 200');
    videoCover.setAttribute('style', 'z-index: 100; background-color:transparent');
    fullCoverOff(id)
}

const fullCoverOn = (id) => {
    const container = document.getElementById(CONTAINER_VIDEO);
    const containerTrackId = container.getAttribute('track-id');
    const containerCover = document.getElementById('video-full-cover');

    if(containerTrackId === id) {
        containerCover.setAttribute('style', 'z-index: 400; background-color:black');
        container.setAttribute('style', 'z-index: 300');
    }
}

const fullCoverOff = (id) => {
    const container = document.getElementById(CONTAINER_VIDEO);
    const containerTrackId = container.getAttribute('track-id');
    const containerCover = document.getElementById('video-full-cover');

    if(containerTrackId === id) {
        container.setAttribute('style', 'z-index: 400');
        containerCover.setAttribute('style', 'z-index: 300; background-color:transparent');
    }
}

const loadingParticipantBanner = () => {
    let banner = document.getElementById('banner-loading-participant');
    if (!banner && window.room.participants.size === 0) {
        banner = document.createElement('div');
        banner.innerHTML = `Esperando participante ...`;
        banner.setAttribute('class', 'loading-participant');
        banner.setAttribute('id', 'banner-loading-participant');
        const container = document.getElementById('master-container');
        container.append(banner);
    }
}

const removeLoadingParticipantBanner = () => {
    const banner = document.getElementById('banner-loading-participant');
    if(banner) {
        banner.remove();
    }
}
