import { DEFAULT_CAMERA_MODE } from './globals'; 

export const webConstraint = (roomSid) => {
    return  {
        sid: roomSid,
        audio: true,
        maxAudioBitrate: 16000,
        video: {
            facingMode: DEFAULT_CAMERA_MODE,
            height: 720,
            // frameRate: 24,
            width: 1280,
        },
        // networkQuality: {
        //     local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
        //     remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
        // },
        region: 'us1',
        // iceTransportPolicy: 'all'
        networkQuality: true,
        // abortOnIceServersTimeout: true,
        iceTransportPolicy: 'relay',
    };
}

export const mobileConstraint = (roomSid) => {
    return  {
        sid: roomSid,
        audio: true,
        maxAudioBitrate: 16000,
        video: {
            facingMode: DEFAULT_CAMERA_MODE,
            heusight: 480,
            // frameRate: 24,
            width: 640,
        },
        // networkQuality: {
        //     local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
        //     remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
        // },
        region: 'us1',
        // iceTransportPolicy: 'all'
        networkQuality: true,
        iceTransportPolicy: 'relay',
        // iceServers: ICE,
    };
}