import React from 'react';
import EndCallSvg from './partials/EndCallSvg';
import RotateSvg from './partials/RotateSvg';
import ShareSvg from './partials/ShareSvg';
import CameraSvg from './partials/CameraSvg';
import NoCameraSvg from './partials/NoCameraSvg';
import MuteSvg from './partials/MuteSvg';
import UnmuteSvg from './partials/UnmuteSvg';
import UnshareSvg from './partials/UnshareSvg';
import Error from './Error';
import mobile from 'is-mobile';
import { withRouter } from "react-router-dom";
import { detect } from 'detect-browser';
import { generateToken, modulesRoom, stopRoom, initRoom, iceServer } from '../utils/http';
import Bugsnag from '../utils/bugsnag';
import { webConstraint, mobileConstraint } from '../utils/constraints';
import NavBar from './partials/NavBar';
import {isSupported} from 'twilio-video';
import noCamera from '../assets/images/videocam_off-24px-agriconecta.png';

import {
    connect,
    attachLocalTrack,
    futureParticipants,
    currentParticipants,
    muteVideoLocalParticipant, 
    muteAudioLocalParticipant,
    switchLocalTrackDevice,
    publishLocalTrack,
    unpublishLocalTrack,
    deleteLocalScreenShare,
    unloadRoom,
    disconnect
} from '../utils/rtc';
import Loading from './partials/Loading';

class Room extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            isLocalMuted: false,
            isLocalCamera: false,
            isLocalFinish: false,
            isLocalSharedScreen: false,
            browser: null,
            banner: true,
            modules: null,
            room: null,
            sharedScreenTrack: null,
            localSharedScreenTrack: null,
            localScreenShareId: null,
            isSupported: null,
            showButtons: false
        }
        this.rotateCamera = this.rotateCamera.bind(this);
        this.exitRoom = this.exitRoom.bind(this);
        this.shareScreen = this.shareScreen.bind(this);
        this.muteLocalTrack = this.muteLocalTrack.bind(this);
        this.stopShareScreen = this.stopShareScreen.bind(this);
        this.muteVideoLocalTrack = this.muteVideoLocalTrack.bind(this);

    }

    componentDidMount() {
        const browser = detect();
        if (isSupported) {
            this.setState({ isSupported: `Supported: ${browser.name}, ${browser.version}, ${browser.os}`});
        } else {
            this.setState({ isSupported: `Not supported: ${browser.name}, ${browser.version}, ${browser.os}`});
        }
    
        this.app()
            .then(run => {
                unloadRoom();
            })
            .catch(async error => {
                let message = error.toString();
                if(message === 'TwilioError: Unable to create Room') {
                    message = `Esta sala no se encuentra disponible o ha finalizado.`;
                } else {
                    message += `${error.toString()} ${this.state.isSupported}`;
                }
                
                Bugsnag.notify(message);
                await this.stopRoom();
                this.setState({
                    error: message,
                    loading: false
                });
            });
    }

    app = async () => {
        try {
            const roomId = this.props.match.params.room || null;
            const userType = this.props.match.params.userType || null; 
            const uuid = this.props.match.params.uid || null;
            
            localStorage.setItem('userType', userType);
            localStorage.setItem('uuid', uuid);
            localStorage.setItem('roomId', roomId);

            this.setState({room: roomId});
            if(!roomId) {
                throw new Error('Room id not found.');
            }
            const modules = await modulesRoom(roomId);
            if(!modules){
                throw new Error('Room not authorized.');
            }
            
            localStorage.setItem('apiKey', modules.accountTypeKey);
            if(parseInt(modules.accountTypeId) === parseInt(process.env.REACT_APP_RTC_M1)){
                this.setState({loading: false, banner: false, modules: modules});
            } else {
                this.setState({loading: false, modules: modules});
            }

            const browser = detect();
            const token = await generateToken(roomId, uuid);
            const localContainer = document.getElementById('list-card-video');
            
            let constraints = {};
            if (mobile()) {
                constraints = mobileConstraint(roomId);
            } else {
                constraints = webConstraint(roomId);
            }
            //ADD Ice servers
            const iceServers = await iceServer();
            constraints.iceServers = iceServers.iceServers;

            console.log('Twilio RoomSid: ', roomId);
            const room = await connect(token.token, constraints);
            
            const getUid = localStorage.getItem('uuid');
            const getUserType = localStorage.getItem('userType');
            const getApiKey = localStorage.getItem('apiKey');
            await initRoom(roomId, getUid, getUserType, getApiKey);
            
            const local = room.localParticipant;
            const localTracks = Array.from(local.tracks.values()).map( (trackPublication) => { return trackPublication.track; });
            await attachLocalTrack(localTracks, localContainer);
            
            this.setState({ browser: browser });

            if (browser.os === 'iOS'){
                await switchLocalTrackDevice(local);
                console.log('Ios module verified');
            }

            currentParticipants();
            futureParticipants();

            this.setState({ showButtons: true });

            disconnect();
            
            return true;

        } catch (e) {
            Bugsnag.notify(e);
            throw e;
        }
    }
    
    rotateCamera = async (e) => {
        try {
            const localTracks = window.room.localParticipant;
            await switchLocalTrackDevice(localTracks);
        } catch (e) {
            Bugsnag.notify(e);
            this.setState({error: e.toString()});
        }
    }
    
    exitRoom = async (e) => {
        e.preventDefault();
        if (window.room) {
            console.log('Room end by user');
            const uid = localStorage.getItem('uuid');
            const userType = localStorage.getItem('userType');
            const getApiKey = localStorage.getItem('apiKey');

            await stopRoom(window.room.sid, uid, userType, getApiKey);
            
            window.room.disconnect();
            window.room = null;
        }
    }
    
    stopRoom = async () => {
        const uid = localStorage.getItem('uuid');
        const userType = localStorage.getItem('userType');
        const getApiKey = localStorage.getItem('apiKey');
        const roomId = localStorage.getItem('roomId');
        console.log('Room is stopped.');
        await stopRoom(roomId, uid, userType, getApiKey);
    }

    muteLocalTrack = async (e) => {
        e.preventDefault();
        try {
            if (window.room) {
                const mutePartipant = await muteAudioLocalParticipant();
                
                this.setState({isLocalMuted: !mutePartipant});
            } else {
                this.setState({error: 'Usuario no conectado a sala.'});
            }
        } catch (e) {
            Bugsnag.notify(e);
            this.setState({error: e.toString()});
        }
    }
    
     muteVideoLocalTrack = async (e) => {
        e.preventDefault();
        try {
            if (window.room) {
                const mutePartipant = await muteVideoLocalParticipant();
                this.setState({isLocalCamera: !mutePartipant});
            } else {
                this.setState({error: 'Usuario no conectado a sala.'});
            }
        } catch (e) {
            Bugsnag.notify(e);
            this.setState({ error: e.toString() });
        }
        
    }

    MuteLocalButton = () => {      
        return (
            <button className="btn btn--rtc ml-3" onClick={this.muteLocalTrack}>
                {(this.state.isLocalMuted) ? <UnmuteSvg /> : <MuteSvg />}
            </button>
        );
    }

    StopCameraButton = () => {
        return (
            <button className="btn btn--rtc ml-3" onClick={this.muteVideoLocalTrack}>
                { (this.state.isLocalCamera) ? <NoCameraSvg /> : <CameraSvg /> }
            </button>
        );
    }

    stopShareScreen = () => {
        const publishTrack = this.state.sharedScreenTrack;
        const streamTrackId = this.state.localSharedScreenTrack.id; 
        try {
            unpublishLocalTrack(publishTrack);
            deleteLocalScreenShare(streamTrackId);
            this.state.localSharedScreenTrack.stop();
            this.setState({isLocalSharedScreen: false});
        } catch(e) {
            Bugsnag.notify(e);
            this.setState({ error: e.toString() });
        }
    }

    ShareScreenButton = () => {
        if (!mobile()) {
            if (this.state.browser 
                && this.state.browser.os !== 'Android OS') {
                    if(!this.state.isLocalSharedScreen) {
                        return (
                            <button className="btn btn--rtc ml-3" onClick={this.shareScreen}>
                                <ShareSvg />
                            </button>);
                    } else {
                        return (
                            <button className="btn btn--rtc ml-3" onClick={this.stopShareScreen}>
                                <UnshareSvg />
                            </button>
                        );
                    }
                
            }
        }
    }

    shareScreen = async (e) => {
        e.preventDefault();
        const screenShared = await this.localScreenShare();
        this.setState({isLocalSharedScreen: screenShared});
    }

    localScreenShare = async () => {
        return navigator.mediaDevices.getDisplayMedia()
            .then(async stream => {
                const streamTrack = stream.getTracks()[0];
                const publishTrack = await publishLocalTrack(streamTrack);
                this.setState({ sharedScreenTrack: publishTrack, localSharedScreenTrack: streamTrack });

                stream.onended = async () => {
                    unpublishLocalTrack(publishTrack);
                    deleteLocalScreenShare(streamTrack.id);
                    this.setState({isLocalSharedScreen: false});
                };

                stream.oninactive = async () => {
                    unpublishLocalTrack(publishTrack);
                    deleteLocalScreenShare(streamTrack.id);
                    this.setState({isLocalSharedScreen: false});
                };

                return publishTrack.isEnabled;
            });
    }

    buttonActions = () => {
        if(this.state.showButtons) {
            return (
                <>
                    <div className="fixed-bottom pb-3 text-center">
                        <div className="pb-3">
                            <button className="btn btn-lg btn-danger btn--circle" onClick={this.exitRoom}>
                                <EndCallSvg />
                            </button>
                        </div>
                        <div>
                            <button className="btn btn--rtc" onClick={this.rotateCamera}>
                                <RotateSvg />
                            </button>
                            { this.StopCameraButton() }
                            { this.MuteLocalButton() }
                            { this.ShareScreenButton() }
                        </div>
                    </div>  
                </>
            );
        }
    }

    imgCover = () => {
        if (this.state.showButtons) {
            return (<img src={noCamera} id="imgCover" alt=""></img>);
        }
    }
    
    render() {
        if(!this.state.loading){
            if (!this.state.error) {
                return (
                    <>
                    {(this.state.banner) ? <NavBar></NavBar> : ''}
                    <div className="container-fluid container--rtc" id="master-container">
                        <div className="pl-3 mt-1 container-card-video">
                            <div className="row row-min" id="list-card-video">
                            </div>
                        </div>
                        <div id="container-video-full">
                            <div id="video-full-src" className="vh-100 vw-100">
                            </div>
                            <div className="vh-100 vw-100 video-full-cover" id="video-full-cover">
                                {this.imgCover()}
                            </div>
                        </div>
                        {this.buttonActions()}  
                    </div>
                    </>
                );
            } else {
                return (
                    <Error error={this.state.error}></Error>
                );
            }
        } else {
            return(
                <>
                <div className="container">
                        <div className="row">
                            <div className="col col-sm-12 col-md-12 col-lg-12">
                                <div className="pt-4 mt-5">
                                    <div className="jumbotron endroom-action" >
                                    <h3 className="display-6">Conectando videollamada.</h3>
                                        <br></br>
                                        <Loading></Loading>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default withRouter(Room);