import React from 'react';
import StopScreenShare from '../../assets/images/stop_screen_share-24px-01.png'

class ShareSvg extends React.Component{
    render () {
        return (
            <img src={StopScreenShare} alt="" loading="lazy" />
        );
    }
}

export default ShareSvg;