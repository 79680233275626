import axios from 'axios';
import Bugsnag from './bugsnag';

const httpInstance = axios.create( {
    baseURL: process.env.API
});

httpInstance.interceptors.response.use(response => { return response }, 
    error => {
        Bugsnag.notify(error);
        return Promise.reject(error);
    }
);

export default httpInstance;