import React from 'react';

class Error extends React.Component {
    error = (message) => {
        return (
            <div className="container pt-4 mt-5">
                <p className="text-center">{message}</p>
            </div>
        );
    }

    render () {
        const { error } = this.props;
        if(error === 'TwilioError: Unable to create Room'){
            return this.error('Videollamada ya no se encuentra disponible o ha finalizado.');
        } else if(error === 'Error: Network Error') {
            return this.error('Ha ocurrido un error al iniciar videollamada.');
        } else {
            return this.error(`${error}`);
        }
    }
}

export default Error;