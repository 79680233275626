import { Container, Row, Col, Button } from "reactstrap";
import React, { useEffect, useState } from "react";
import { roomRepository } from "../infra/index";
import Bugsnag from "../utils/bugsnag";
import { useHistory } from "react-router-dom";

function Index() {
  const history = useHistory();
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    async function load() {
      try {
        setLoading(true);

        const rooms = await roomRepository.loadRooms();
        setRooms(rooms);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        Bugsnag.notify(e);
      }
    }

    load();
  }, []);

  const handleForceEndRoom = async (room) => {
    try {
      await roomRepository.endRoomForce(room.roomSid);

      const rooms = await roomRepository.loadRooms();
      setRooms(rooms);
    } catch (e) {
      Bugsnag.notify(e);
    }
  };

  const handleOpenRoom = (room) => {
    const roomUrl = `call/${room.roomSid}/technician/${room.uidReceiver}`;
    history.push(roomUrl);
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="float-right mt-2">
            {rooms && rooms.length ? <Button>Reload</Button> : null}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {loading && !rooms.length ? (
            <code>Loading rooms...</code>
          ) : !loading && rooms.length ? (
            <table className="mt-2 table table-striped sm">
              <thead>
                <tr>
                  <th>Room sid</th>
                  <th>Room status</th>
                  <th>Room created at</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {rooms.map((item) => {
                  return (
                    <tr key={item.roomSid}>
                      <td>{item.roomSid}</td>
                      <td>{item.status}</td>
                      <td>{item.createdAt}</td>
                      <td>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleForceEndRoom(item)}
                        >
                          Terminar
                        </button>
                        <a
                          href="#top"
                          onClick={() => handleOpenRoom(item)}
                          className="btn btn-primary btn-sm"
                        >
                          Abrir
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h4>Rooms not found</h4>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Index;
