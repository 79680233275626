import axios from './axios';

export const generateToken = async (roomId, personId) => {
    return axios.post(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/room/token`, {
        uid: personId,
        sid: roomId,
    })
    .then((response) => {
        return response.data;
    });
}

// export const rooms = async (apiKey) => {
//     return axios.get(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/room?api_key=${apiKey}&status=in-progress`)
//     .then((response) => {
//         return response.data;
//     });
// }

export const stopRoom = async (roomId, uid, userType, apiKey) => {
    return axios.patch(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/room`, {
        api_key: apiKey,
        sid: roomId,
        uid: uid,
        user_type: userType,
    })
    .then((response) => {
        return response.data;
    });
}

export const modulesRoom = async(roomId) => {
    return axios.post(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/room/modules`, {
        sid: roomId,
    })
    .then((response) => {
        return response.data;
    });
}

export const ratingRoom = async(roomId, rating, comment=null) => {
    return axios.post(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/room/rating`, {
        sid: roomId,
        rating: rating,
        comment: comment
    })
    .then((response) => {
        return response.data;
    });
}

export const initRoom = async (roomId, uid, userType, apiKey) => {
    return axios.post(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/room/load`, {
        api_key: apiKey,
        sid: roomId,
        uid: uid,
        user_type: userType
    })
    .then((response) => {
        return response.data;
    });
}

export const iceServer = async (roomId, uid, userType, apiKey) => {
    return axios.post(`${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/room/ice-servers`, {
        api_key: apiKey,
        sid: roomId,
        uid: uid,
        user_type: userType
    })
    .then((response) => {
        return response.data;
    });
}