import React from 'react';
import logo from '../../assets/images/logo-blanco.png'
class NavBar extends React.Component {
    render () {
        return (
            <nav className="navbar navbar-expand-lg bg-rtc fixed-top">
                <a className="navbar-brand" href="#top">
                    <img src={logo} height="30" className="d-inline-block align-top" alt="" loading="lazy" />
                </a>
            </nav>
        );
    }
}

export default NavBar;