import axios from "../utils/axios";

const baseUrl = process.env.REACT_APP_API;
const apiVersion = process.env.REACT_APP_API_VERSION;
const apiKey = process.env.REACT_APP_RTC_API_KEY;

async function client(path, method = "GET", params) {
  return new Promise((resolve, reject) => {
    const config = {
      baseURL: `${baseUrl}/${path}`,
      method: method,
      headers: {
        "x-api-key": apiKey,
      },
    };

    if (method !== "GET") {
      config["data"] = params;
    } else config["params"] = params;

    axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export default client;
