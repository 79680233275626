import React from 'react';
import Temp from './views/Temp';
// import Index from './views/Index';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Room from './views/Room';
import Disconected from './views/Disconected';
// import Room from "./views/RoomV2";
 
function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/call/:room/:userType/:uid">
            <Room />
          </Route>
          <Route path="/" exact>
            <Temp />
          </Route>
          <Route path="/end/:room" exact>
            <Disconected />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
