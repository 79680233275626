import React from 'react';
import MicOff from '../../assets/images/mic_off-24px-01.png'

class UnmuteSvg extends React.Component{
    render () {
        return (
            <img src={MicOff} alt="" loading="lazy" />
        );
    }
}

export default UnmuteSvg;